import React from 'react'
import '../../assets/css/style.css'

const CheckOutExperience = () => {
	return (
		<>
			<div className=' container-xl conatiner-sm-fluid '>
				<img src={require('../../assets/img/App Featyres-min.png')} className='img-fluid' alt='' />
			</div>
			{/* <section
          id="hero"
          className="d-flex align-items-center"
          style={{ backgroundImage: `url(${BackgroundImage})`, height:"90rem" }}
        >
          <div className="container">
            <div className="row text-center">
    

       <div 
                className="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
              ></div>
              <div
                className="col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img"
                data-aos="fade-up">
  
       <img  src={require("../../assets/img/App Featyres-min.png")} className="img-fluid" alt=""/> 
      </div> 
      </div> 
      </div>
       </section>  */}
		</>
	)
}

export default CheckOutExperience
