import React from 'react'
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import '../../assets/css/style.css'
import BackgroundImage from '../../assets/img/Background-Image.png'
const Hero = () => {
	return (
		<>
			<section id='hero' className='d-flex align-items-center' style={{ backgroundImage: `url(${BackgroundImage})` }}>
				<div className='container'>
					<div className='row'>
						<div
							className='col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1'
							data-aos='fade-up'>
							<div className='card bg-white p-5 w-100 xl:h-75 sm:h-100 md:h-100'>
								<h1>Muzien App</h1>
								<h2>Muzien provides a one-stop solution for all your beauty needs, enabling you to easily book salon<br/> appointments and avoid lengthy waiting times, all through our user-friendly app.</h2>

								<div className='row gap-4'>
									<h2>Download the App</h2>
									<div className='col-md-3'>
										<img src={require('../../assets/img/google.png')} height='50px' alt='' />
									</div>
									<div className='col-md-3'>
										<img src={require('../../assets/img/app.png')} height='50px' alt='' />
									</div>
									<div className='col-md-6'></div>
								</div>
							</div>
						</div>
						<div className='col-lg-2 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img' data-aos='fade-up'></div>
						<div className='col-lg-3 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img' data-aos='fade-up'>
							<img src={require('../../assets/img/Hero Section Mobile mobile mockup.png')} className='img-fluid' alt='' />
						</div>
						<div className='col-lg-1 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img' data-aos='fade-up'></div>
					</div>
					<div className='d-flex w-full text-white mt-2 gap-3'>
						<FaFacebookF />
						<FaInstagram />
						<FaTwitter />
						<FaYoutube />
					</div>
				</div>
			</section>
		</>
	)
}

export default Hero
