import React from 'react'
import BackgroundImage from '../assets/img/Background-Image.png'

const PrivacyPolicy = () => {
	return (
		<>
			<div className='relative'></div>
			<div
				id='hero'
				className='d-flex align-items-center justify-content-center relative'
				style={{ backgroundImage: `url(${BackgroundImage})`, zIndex: '1' }}>
				<div className='text-white '>
					<h1 className='text-white'>
						<b>Privacy Policy</b>
					</h1>
				</div>
			</div>
			<div className='card container fixed ' style={{ zIndex: '2', top: '-80px' }}>
				<div className='container px-4 mt-4'>
					<div>
						<h1>
							<b>
								AT MUZIEN, <br />
								WE TAKE YOUR PRIVACY VERY SERIOUSLY
							</b>
						</h1>
					</div>

					<div className='container p-5'>
						<p>
							This Privacy Policy outlines the types of information we collect, how we use it, and how we protect it.
							<br />
							<br />
							<br />
							Information we collect:
							<br />
							When you register on the Muzien app, we collect personal information such as your name, email address, phone number, and location. We
							also collect information about your appointments, including the services you book, the date and time of your appointments, and any
							special requests you make. Additionally, we collect information about your device, such as your device type, operating system, and
							browser.
							<br />
							<br />
							<br />
							<br />
							How we use your information:
							<br /> We use the information we collect to provide you with the best possible salon booking experience. This includes processing your
							appointments, communicating with you about your appointments and any changes or updates, and providing you with relevant information
							about salons in your area. We may also use your information to improve our app and services, and to personalize your experience with
							Muzien.
							<br />
							<br />
							<br />
							<br />
							<br />
							How we share your information:
							<br /> We may share your personal information with the salons you book appointments with. This includes your name, contact information,
							and any information related to your appointments. We may also share your information with third-party service providers who assist us
							with processing payments, analyzing data, and providing customer support. We will only share your information with these parties to the
							extent necessary to provide our services and will require them to keep your information confidential.
							<br />
							<br />
							<br />
							<br />
							How we protect your information:
							<br /> We take the security of your information seriously and use industry-standard security measures to protect it. This includes
							encryption, secure servers, and regular security audits. However, no method of transmission over the internet or electronic storage is
							100% secure, and we cannot guarantee the absolute security of your information. Your rights: You have the right to access, update, and
							delete your personal information at any time. You can do this by logging into your account on the Muzien app or by contacting us at
							support@muzien.com. You also have the right to opt-out of marketing communications at any time.
							<br />
							<br />
							<br />
							<br />
							Changes to this Privacy Policy:
							<br /> We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you
							of any material changes by email or through the Muzien app.
							<br />
							<br />
							<br />
							<br /> Contact us: <br />
							If you have any questions or concerns about this Privacy Policy or how we handle your information, please contact us at
							support@muzien.com.
						</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default PrivacyPolicy
