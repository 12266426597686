import React from 'react'
import '../../assets/css/style.css'

const Video = () => {
	return (
		<>
			{' '}
			<section id='about-video' className='about-video'>
				<div className='container' data-aos='fade-up'>
					<div className='row p-5 youtubeBackground'>
						<div className='col-lg-5 pt-3 pt-lg-0 content' data-aos='fade-left' data-aos-delay='100'>
							<h3 className='text-white'>how to use the app perfectly.</h3>
							<br />
							<p>
							With Muzien's salon booking app, you can easily browse services and select a top-rated beauty professional in just a few taps. Once you've chosen your treatment and professional, simply select a time and date that works for you and confirm your booking through the app's secure payment system. With reminders and updates, managing your appointments has never been simpler.
							</p>
						</div>
						<div className='col-lg-2 video-box align-self-baseline' data-aos='fade-right' data-aos-delay='100'></div>
						<div className='col-lg-4 card video-box align-self-baseline' data-aos='fade-right' data-aos-delay='100'>
							<img src={require('../../assets/img/About Our App Mobile mockup.png')} className='img-fluid' alt='' />
							<a href='https://www.youtube.com/watch?v=jDDaplaOz7Q' className='glightbox play-btn mb-4' data-vbtype='video' data-autoplay='true'>
								{' '}
							</a>
						</div>
						<div className='col-lg-1 video-box align-self-baseline' data-aos='fade-right' data-aos-delay='100'></div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Video
