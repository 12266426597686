import React from 'react'
import "../../assets/css/style.css";
import { FaCheck } from "react-icons/fa";


const AboutOurApp = () => {
  return (
    <> <section id="features" className="features">
    <div className="container">
      <div className="section-title">
        <h2>About Our App</h2>
        <p>
        Muzien simplifies salon bookings. With a user-friendly interface and 24/7 customer support,
        <br/> this app makes booking salon services easy and hassle-free. With experienced professionals,
        <br/>  enjoy a stress-free and enjoyable salon experience. Book now.
     </p> </div>

      <div className="row no-gutters">
        <div className="col-xl-6 d-flex align-items-stretch order-2 order-lg-1">
          <div className="content d-flex flex-column justify-content-center">
            <div className="row">
              <div
                className="col-md-12 icon-box border p-2 "
                data-aos="fade-up"
              >
                <h4>
                  <FaCheck /> &nbsp; &nbsp; Save time  & convenient
                </h4>
                <p>
                Customers can easily book appointments from their smartphones, tablets, or computers, at any time of the day, without having to call or visit the salon.
                </p>
              </div>
              <div
                className="col-md-12 icon-box border p-2"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h4>
                  <FaCheck />
                  &nbsp; &nbsp; Easy to use
                </h4>
                <p>
                An easy-to-use app can increase user satisfaction by providing a seamless and hassle-free experience, which can lead to positive reviews and repeat business.
                </p>
              </div>
              <div
                className="col-md-12 icon-box border p-2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h4>
                  <FaCheck />
                  &nbsp; &nbsp; Best user experince
                </h4>
                <p>
                The app provides ratings and reviews of beauty professionals, allowing customers to make informed decisions and choose the best professional for their needs.
                </p>
              </div>
             
            </div>
          </div>
        </div>
        <div
          className="image col-xl-6 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <img
            src={require("../../assets/img/About Our App Mobile mockup.png")}
            className="img-fluid"
            alt=""
          />
        </div>
      </div>
    </div>
  </section></>
  )
}

export default AboutOurApp