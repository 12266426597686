import React, { useRef } from "react";
import { FaBackward, FaForward } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const MySlider = () => {
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Show 5 slides at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    centerPadding: "300px", // Set the padding for center mode to 50px
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
      <img
                src={require("../../assets/img/mobile-1.png")}
                className="img-fluid"
                alt=""
              />
                    <img
                src={require("../../assets/img/mobile-1.png")}
                className="img-fluid"
                alt=""
              />
                    <img
                src={require("../../assets/img/mobile-1.png")}
                className="img-fluid"
                alt=""
              />
                    <img
                src={require("../../assets/img/mobile-1.png")}
                className="img-fluid"
                alt=""
              />
                    <img
                src={require("../../assets/img/mobile-1.png")}
                className="img-fluid"
                alt=""
              />
                    <img
                src={require("../../assets/img/mobile-1.png")}
                className="img-fluid"
                alt=""
              />
                    <img
                src={require("../../assets/img/mobile-1.png")}
                className="img-fluid"
                alt=""
              />
                    <img
                src={require("../../assets/img/mobile-1.png")}
                className="img-fluid"
                alt=""
              />
                    <img
                src={require("../../assets/img/mobile-1.png")}
                className="img-fluid"
                alt=""
              />
          
      </Slider>
      <div className="slider-buttons">
      <div
                    className="bg-gray-300 hover:bg-pixer rounded-full ml-4"
                    onClick={previous}
                  >
                    <FaBackward className="h-12 p-2 text-black" />
                  </div>
                  <div
                    className="bg-gray-300 hover:bg-pixer rounded-full mr-5"
                    onClick={next}
                  >
                    <FaForward className="h-12 p-2 text-black" />
                  </div>
      </div>
    </div>
  );
};

export default MySlider;