import React from 'react'
import './assets/css/style.css'
import Registration from './pages/Registration'

import { Route, Routes } from 'react-router-dom'
import Footer from './components/Footer/Footer'
import Navbar from './components/Navbar/Navbar'
import AboutUs from './pages/AboutUs'
import Home from './pages/Home'
import PrivacyPolicy from './pages/PrivacyPolicy'

const App = () => {
	return (
		<div>
			<Navbar />

			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route exact path='/registration' element={<Registration />} />
				<Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
				<Route exact path='/about-us' element={<AboutUs />} />
			</Routes>

			{/* <Home/> */}
			{/* <Registration/> */}
			<Footer />
		</div>
	)
}

export default App
