import React from 'react'
import '../../assets/css/style.css'
import { FaFacebookF, FaLine } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { FaYoutube } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

const Footer = () => {
	return (
		<>
			{' '}
			<footer id='footer'>
				<div className='footer-top '>
					<div className='container mt-5'>
						<div className='row'>
							<div className='col-lg-4 col-md-6 footer-contact'>
								<img style={{ width: '200px' }} src={require('../../assets/img/logo.png')} alt='logo' />
								<p>
								Book your next beauty appointment with ease using our salon booking app - connecting you with top-rated professionals in your area.
								</p>

								<div className='d-flex gap-4 mt-3'>
									<a href='#' className='facebook'>
										<FaFacebookF size={25} />
									</a>
									<a href='#' className='instagram'>
										<FaInstagram size={25} /> 
									</a>
									<a href='#' className='twitter'>
										<FaTwitter size={25} /> 
									</a>
									<a href='#' className='youtube'>
										<FaYoutube size={25} /> 
									</a>
								</div>
							</div>

							<div className='col-lg-4 col-md-6 footer-links'>
								<h4>Quick Links</h4>
								<ul>
									<li>
										<i className='bx bx-chevron-right'></i> <NavLink to='#'>About</NavLink>
									</li>
									<li>
										<i className='bx bx-chevron-right'></i> <NavLink to='#'>Features</NavLink>
									</li>
									<li>
										<i className='bx bx-chevron-right'></i> <NavLink to='#'>User Experience</NavLink>
									</li>
									<li>
										<i className='bx bx-chevron-right'></i> <NavLink to='/about-us'>About Us</NavLink>
									</li>
									<li>
										<i className='bx bx-chevron-right'></i> <NavLink to='/privacy-policy'>Privacy policy</NavLink>
									</li>
								</ul>
							</div>

							<div className='col-lg-4 col-md-6 footer-newsletter'>
								<h4>Newsletter</h4>
								<p>Subscribe our newsletter to get our latest updates & news</p>
								<form action='' method='post'>
									<input type='email' name='email' placeholder='your@email.com' />
									<input type='submit' value='Suscribe' />
								</form>
							</div>
						</div>
					</div>
				</div>

				<div className='container py-4'>
					<div className='text-center' style={{ fontSize: '18px' }}>
						<p>&copy; Copyright 2021 .Ojjomedia. All Right Reserved.</p>
					</div>
				</div>
			</footer>
			<a href='#' className='back-to-top d-flex align-items-center justify-content-center'>
				<i className='bi bi-arrow-up-short'></i>
			</a>
		</>
	)
}

export default Footer
