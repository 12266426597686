import React from 'react'
;
import BackgroundImage from "../assets/img/Background-Image.png";

const AboutUs = () => {
  return (
    <div>
       <>
      <div className="relative"></div>
      <div
        id="hero"
        className="d-flex align-items-center justify-content-center relative"
        style={{ backgroundImage: `url(${BackgroundImage})`, zIndex: "1" }}
      >
        <div className="text-white ">
          <h1 className="text-white"><b>About Us</b></h1>
        </div>
      </div>
      <div className="card container fixed " style={{ zIndex: "2", top: "-80px" }}>
        <div className="container px-4 mt-4">
          <div>
          
          <h1><b>MUZIEN IS AN INNOVATIVE <br/>
SALON REGISTRATION APP</b></h1>
          
          </div>
        
          <div className="container p-5">
            <p>
            That is designed to make the lives of salon owners and customers easier. Whether you're a salon owner looking to streamline your business operations or a customer searching for the perfect salon experience, Muzien has you covered.
For salon owners, Muzien provides an all-in-one platform that simplifies the booking process, helps you manage your appointments, and keeps track of your customer information. With Muzien, you can easily create an online presence for your salon, attract new customers, and grow your business.<br/>
<br/>
<br/>

Muzien is also a game-changer for customers looking for the perfect salon experience. With Muzien, you can browse through a wide range of salons in your area, compare prices, and book your appointments with just a few clicks. No more waiting on the phone or dealing with busy signals – Muzien makes the booking process fast and easy.
The Muzien app is intuitive and user-friendly, with a sleek and modern design that makes it easy to navigate. It is available for download on both iOS and Android devices, so you can use it no matter what type of device you prefer.
Whether you're a salon owner or a customer, Muzien is the perfect app to help you save time, simplify your life, and achieve your goals. With Muzien, you can take your salon experience to the next level and get the most out of your business or your beauty routine.
<br/>
<br/>
<br/>


<span><h3> <b>CEO MESSAGE</b></h3></span>
Muzien is also a game-changer for customers looking for the perfect salon experience. With Muzien, you can browse through a wide range of salons in your area, compare prices, and book your appointments with just a few clicks. No more waiting on the phone or dealing with busy signals – Muzien makes the booking process fast and easy.
The Muzien app is intuitive and user-friendly, with a sleek and modern design that makes it easy to navigate. It is available for download on both iOS and Android devices, so you can use it no matter what type of device you prefer.
Whether you're a salon owner or a customer, Muzien is the perfect app to help you save time, simplify your life, and achieve your goals. With Muzien, you can take your salon experience to the next level and get the most out of your business or your beauty routine.
<br/>
<br/>
<br/>
            </p>
          </div>
        </div>
      </div>
    </>
    </div>
  )
}

export default AboutUs