import React from 'react'
import '../../assets/css/style.css'

const ContactRegistration = () => {
	return (
		<>
			<section id='details' className='details'>
				<div className='container'>
					<div className='row content'>
						<div className='col-md-2 order-1 order-md-2' data-aos='fade-left'>
							{/* <img src="assets/img/details-4.png" height="30%" className="img-fluid" alt=""> */}
						</div>
						<div className='col-md-4 order-1 order-md-2' data-aos='fade-left'>
							<img src={require('../../assets/img/Illustration.png')} className='img-fluid' alt='' />
						</div>
						<div className='col-md-6 pt-5 order-2 order-md-1' data-aos='fade-up'>
							<h3>Register Your Saloon Now</h3>
							<p>Unlock the door to a world of beauty and pampering at Register Salon - where excellence in service is always our top priority.</p>
							<form action='forms/contact.php' method='post' className='php-email-form'>
								<div className='form-group mb-3'>
									<b>
										{' '}
										<label>Saloon Name </label>
									</b>
									<input type='text' name='name' className='form-control' id='name' placeholder='Saloon Name' required />
								</div>
								<div className='form-group mt-3 '>
									<b>
										<label>Email Address </label>{' '}
									</b>
									<input type='email' className='form-control' name='email' id='email' placeholder='Your Email' required />
								</div>

								<div className='form-group mt-3'>
									<b>
										{' '}
										<label>Phone Number </label>
									</b>
									<input type='tel' id='phone' name='phone' className='form-control' maxlength='15' placeholder='+1 123-456-7890' required></input>
								</div>
								<div className='form-group mt-3'>
									<b>
										<label>Sale Registration No</label>
									</b>
									<input type='text' className='form-control' name='company' id='company' placeholder='Registration No' required />
								</div>
								<div className='form-group mt-3'>
									<b>
										<label>Saloon Location</label>
									</b>
									<input type='text' className='form-control' name='company' id='company' placeholder='Location' required />
								</div>
								<div className='form-group mt-3'>
									<input type='checkbox' id='subscribe' name='subscribe' value='yes' />
									&nbsp;&nbsp; you agree to our friendly <a href='/'>Privacy Policy</a>
								</div>
								<div className='mt-3 text-center '>
									<button className='btn btn-lg youtubeBackground px-5'>Send Message</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default ContactRegistration
