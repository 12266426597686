import React, { useState } from 'react';
import "../../assets/css/style.css";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
  <header id="header" className="fixed-top header-transparent">
      <div className="container d-flex align-items-center justify-content-between">
        <div className="logo">
          <NavLink to="/">
            <img src={require('../../assets/img/logo.png')} alt="" className="img-fluid" />
          </NavLink>
        </div>

        <nav id="navbar" className={`navbar ${showMenu ? 'navbar-mobile' : ''}`}>
          <ul>
            <li>
              <NavLink className="nav-link scrollto" exact to="/" onClick={() => setShowMenu(false)}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className="nav-link scrollto" exact to="/registration" onClick={() => setShowMenu(false)}>
                Registration
              </NavLink>
            </li>
            <li>
              <NavLink className="nav-link scrollto" exact to="/about-us" onClick={() => setShowMenu(false)}>
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink className="nav-link scrollto" exact to="/privacy-policy" onClick={() => setShowMenu(false)}>
                Privacy Policy
              </NavLink>
            </li>
            {/* <li>
              <NavLink className="nav-link scrollto" to="" onClick={() => setShowMenu(false)}>
                Contact Us
              </NavLink>
            </li> */}
            <li>
              <a className="getstarted scrollto justify-content-center" href="#features">
                Download
              </a>
            </li>
          </ul>
          <button className="mobile-nav-toggle" onClick={handleToggleMenu}>
            {showMenu ? <AiOutlineClose /> : <BiMenuAltRight />}
          </button>
        </nav>
      </div>
    </header>
    </>
  );
};

export default Navbar;