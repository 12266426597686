import React from 'react'
import AboutOurApp from '../components/AboutOurApp/AboutOurApp'
import CheckOutExperience from '../components/CheckOutExperience/CheckOutExperience'
import ContactForm from '../components/ContactForm/ContactForm'
import Details from '../components/Details/Details'
import Hero from '../components/Hero/Hero'
import MySlider from '../components/Slider/MySlider'
import Video from '../components/Video/Video'

const Home = () => {
	return (
		<div>
			{/* <Navbar /> */}
			<Hero />
			<main id='main'>
				<AboutOurApp />
				<CheckOutExperience />
				<section id='gallery' className='gallery'>
					<div className='container' data-aos='fade-up'>
						<div className='section-title mb-5'>
							<h2>CHECKOUT MUZIEN USER EXPERIENCE</h2>
							<p>Muzien’s salon booking app features a clean, intuitive interface that <br/>  makes booking beauty services a breeze. With easy-to-use menus and filters, customers can quickly <br/>  find the services they need and schedule an appointment with their preferred beauty professional. <br/>  The app's simple, streamlined design ensures a hassle-free experience for customers every time.</p>
						</div>
					</div>
					<MySlider />
				</section>
				<Details />
				<Video />
			</main>
			<ContactForm />
			{/* <Footer /> */}
		</div>
	)
}

export default Home
