import React from 'react'
import { FaDownload, FaStar, FaThumbsUp } from 'react-icons/fa'
import '../../assets/css/style.css'

const Details = () => {
	return (
		<>
			{' '}
			<section id='details' className='details'>
				<div className='container'>
					<div className='row content'>
						<div className='col-md-2 order-1 order-md-2' data-aos='fade-left'>
							{/* <img src="assets/img/details-4.png" height="30%" className="img-fluid" alt=""> */}
						</div>
						<div className='col-md-4 order-1 order-md-2' data-aos='fade-left'>
							<img src={require('../../assets/img/Download App Now Mobile Mockup.png')} className='img-fluid' alt='' />
						</div>
						<div className='col-md-6 pt-5 order-2 order-md-1' data-aos='fade-up'>
							<h3>Download Our App Now</h3>
							<p>
							Download the Muzzien app today and experience hassle-free beauty bookings! Get instant access to top-rated beauty professionals in your area, browse services and prices, and book your next appointment with just a few taps. Available on the App Store and Google Play.
							</p>
							<div className='d-flex w-50 h-10 gap-2'>
								<img src={require('../../assets/img/google.png')} height='50px' alt='' />
								<img src={require('../../assets/img/app.png')} height='50px' alt='' />
							</div>
							<section id='counts' className='counts mt-3'>
								<div className='container'>
									<div className='row gap-2'>
										<div className='col-lg-3 col-6 border p-2 background'>
											<div className='count-box background'>
												<span data-purecounter-start='0' data-purecounter-end='232' data-purecounter-duration='1' className='purecounter'></span>
												<FaDownload />
												<p>Downloads</p>
												<p>6874</p>
											</div>
										</div>

										<div className='col-lg-3 col-6 border p-2 background'>
											<div className='count-box background'>
												<span data-purecounter-start='0' data-purecounter-end='521' data-purecounter-duration='1' className='purecounter'></span>
												<FaThumbsUp />
												<p>Likes</p>
												<p>6845674</p>
											</div>
										</div>

										<div className='col-lg-3 col-6  border p-2 background'>
											<div className='count-box background'>
												<span data-purecounter-start='0' data-purecounter-end='1463' data-purecounter-duration='1' className='purecounter'></span>
												<FaStar />
												<p>Rating</p>
												<p>533454</p>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Details
